import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Flex, Box, Badge, Link, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import getImage from '@solid-ui-components/utils/getImage'

import { FaRegClock } from 'react-icons/fa'

const styles = {
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 300,
    maxWidth: [`none`, null, null, 500],
    cursor: `pointer`,
    p: 3
  },
  card: {
    overflow: `hidden`,
    height: `full`
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    minHeight: `15rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  }
}

const BlogBlock01 = ({
  content: { kicker, title, text_1, text_2, collection, buttons }
}) => (
  <Container>
    <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
    {collection && (
      <Reveal effect='fadeIn' duration={1}>
        <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -3 }}>
          {collection.map(
            (
              {
                container,
                kicker,
                title,
                description,
                text_1,
                text_2,
                images,
                avatar
              },
              index
            ) => (
              <Box key={`item-${index}`} sx={styles.wrapper}>
                <ContentContainer
                  content={container}
                  variant='cards.interactive'
                  sx={styles.card}
                >
                  <Flex as='article' sx={styles.content}>
                    {/* Image */}
                    <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                      <Box sx={styles.imageWrapper}>
                        <Img
                          image={getImage(images?.[0]?.src)}
                          alt={images?.[0]?.alt}
                          css={css(styles.image)}
                        />
                      </Box>
                    </Box>
                    <Box sx={styles.body}>
                      {/* Category */}
                      <Box sx={{ display: `inline-block` }}>
                        <Box mb='3'>
                          <Badge
                            variant='tag'
                            sx={{ bg: `alphaLighter` }}
                            color={kicker?.color}
                          >
                            {kicker?.text}
                          </Badge>
                        </Box>
                      </Box>
                      {/* Title */}
                      <ContentText
                        content={title}
                        variant='h5'
                        sx={{ flex: [0, 0, `auto`] }}
                      />
                      {/* Excerpt */}
                      <ContentText
                        content={description}
                        variant='small'
                        sx={{ flex: `auto`, mb: 3 }}
                      />
                      {/* Footer */}
                      <Box sx={{ variant: `text.small` }}>
                        <Flex sx={styles.footerWrapper}>
                          <Img
                            image={getImage(avatar?.src)}
                            alt={avatar?.alt}
                            css={css(styles.avatar)}
                          />
                          <Flex sx={styles.postInfo}>
                            {/* Author */}
                            <ContentText
                              content={text_1}
                              sx={{
                                display: `inline-block`,
                                flexBasis: `full`
                              }}
                            >
                              <Link color={text_1?.color}>
                                <strong>{text_1?.text}</strong>
                              </Link>
                            </ContentText>
                            {/* Info */}
                            <Flex>
                              {text_2 && (
                                <>
                                  <ContentText
                                    content={text_2}
                                    sx={{ display: `inline-block` }}
                                    mr='2'
                                  >
                                    {text_2?.textGroup?.[0]}
                                  </ContentText>
                                  <ContentText
                                    content={text_2}
                                    sx={{ display: `inline-block` }}
                                  >
                                    <FaRegClock
                                      css={{ verticalAlign: `middle` }}
                                    />{' '}
                                    {text_2?.textGroup?.[1]}
                                  </ContentText>
                                </>
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </ContentContainer>
              </Box>
            )
          )}
        </Flex>
      </Reveal>
    )}
    <ContentButtons content={buttons} />
  </Container>
)

export default WithDefaultContent(BlogBlock01)

import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import { ImQuotesRight } from 'react-icons/im'
import { AiFillStar } from 'react-icons/ai'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import getImage from '@solid-ui-components/utils/getImage'

const styles = {
  avatar: {
    size: 120,
    display: `block`,
    bg: `omegaLighter`,
    borderTopColor: `omegaLighter`,
    borderTopWidth: `lg`,
    borderTopStyle: `solid`,
    borderRadius: `full`,
    boxSizing: `content-box`,
    mx: `auto`,
    mt: -90,
    mb: 3
  }
}

const TestimonialsBlock01 = ({
  content: { kicker, title, text_1, text_2, collection }
}) => (
  <Container>
    <StackTitle
      kicker={kicker}
      title={title}
      lines={[text_1, text_2]}
      space={5}
    />
    <Flex sx={{ m: -3, flexWrap: `wrap` }}>
      {collection?.map(
        ({ container, avatar, kicker, title, description }, index) => (
          <Box
            key={`item-${index}`}
            sx={{ flexBasis: [`1`, null, `1/2`, `1/3`], flexGrow: 1, p: 3 }}
          >
            <Reveal effect='fadeInLeft' delay={0.25 * (index + 2)}>
              <ContentContainer
                content={container}
                variant='cards.paper'
                sx={{ textAlign: `center` }}
              >
                <Img
                  image={getImage(avatar?.src)}
                  alt={avatar?.alt}
                  objectPosition='top center'
                  css={css(styles.avatar)}
                />
                <ImQuotesRight css={css({ size: `icon.xs`, color: `alpha` })} />
                <ContentText content={kicker} variant='h5' />
                <ContentText content={title} variant='h6' />
                <ContentText content={description} variant='p' />
                {Array.from({ length: 5 }, (_, i) => (
                  <AiFillStar key={`item-${i}`} css={css({ color: `beta` })} />
                ))}
              </ContentContainer>
            </Reveal>
          </Box>
        )
      )}
    </Flex>
  </Container>
)

export default WithDefaultContent(TestimonialsBlock01)

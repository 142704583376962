import React from 'react'
import { Container, Flex } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import StackTitle from '@solid-ui-components/StackTitle'
import FlexImage from '@solid-ui-components/FlexImage'
import FlexContent from '@solid-ui-components/FlexContent'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  items: {
    flexWrap: `wrap`,
    mx: [-2, -4],
    '& > div': {
      flex: 1,
      px: [2, 4],
      textAlign: [`center`, `unset`]
    }
  },
  subTitle: {
    maxWidth: 10
  }
}

const FeaturesWithPhotoBlock05 = ({
  content: {
    kicker,
    title,
    text_1,
    text_2,

    images,
    collection,
    buttons
  },
  reverse
}) => (
  <Container sx={{ position: `relative` }}>
    <Flex
      sx={{
        alignItems: [null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`
        ],
        mx: [null, null, null, -4]
      }}
    >
      <FlexImage reverse={reverse}>
        <ContentImages content={{ images }} reverse={reverse} />
      </FlexImage>
      <FlexContent reverse={reverse}>
        <StackTitle
          kicker={kicker}
          title={title}
          lines={[text_1, text_2]}
          align={[`center`, `left`]}
          space={0}
        />
        {collection && (
          <>
            <Divider space={1} />
            <Flex sx={styles.items}>
              {collection.map(({ kicker, title, description }, index) => (
                <Reveal
                  key={`item-${index}`}
                  effect='fadeInPop'
                  delay={0.3 * (index + 1)}
                  duration={0.5}
                >
                  <Flex sx={{ flexDirection: `column`, height: `full` }}>
                    <ContentText content={kicker} variant='h2' mb='1' />
                    <ContentText content={title} variant='h6' />
                    <ContentText content={description} mt='auto' mb='0' />
                  </Flex>
                </Reveal>
              ))}
            </Flex>
          </>
        )}
        <ContentButtons content={buttons} space={3} />
      </FlexContent>
    </Flex>
    <FlexOverlapFade direction={reverse ? 'ltr' : 'rtl'} />
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock05)

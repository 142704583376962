import React, { useState } from 'react'
import { Container, Flex, Box, Badge, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Tabs from '@solid-ui-components/Tabs'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  middleBox: {
    position: `relative`,
    zIndex: 2
  },
  prevPrice: {
    textDecoration: `line-through`,
    opacity: 0.2
  },
  saveBadge: {
    position: `absolute`,
    top: 3,
    right: 3
  }
}

const PricingBlock01 = ({
  content: { kicker, title, text_1, text_2, collection, buttons }
}) => {
  const [plan, setPlan] = useState(0)

  return (
    <Container sx={{ textAlign: `center` }}>
      <StackTitle kicker={kicker} title={title} lines={[text_1]} />
      <Tabs tabs={text_2?.textGroup} onChange={setPlan} variant='pill' />
      {collection && (
        <Flex
          sx={{
            flexWrap: `wrap`,
            alignItems: `center`,
            justifyContent: `center`,
            m: -3
          }}
        >
          {collection.map(
            (
              {
                container,
                kicker,
                title,
                description,
                text_1,
                text_2,
                icon,
                collection,
                buttons
              },
              i
            ) => (
              <Box
                key={`item-${i}`}
                sx={{ flex: [`auto`, 1], minWidth: 350, maxWidth: 500, p: 3 }}
              >
                <Reveal
                  threshold={0.7}
                  effect={
                    collection.length === 3
                      ? i === 0
                        ? 'fadeInDeepRight'
                        : i === collection.length - 1
                        ? 'fadeInDeepLeft'
                        : null
                      : 'fadeInUp'
                  }
                  css={css(
                    i !== 0 && i !== collection.length - 1 && styles.middleBox
                  )}
                >
                  <ContentContainer
                    content={container}
                    variant='cards.paper'
                    sx={{ position: `relative` }}
                  >
                    {text_1?.textGroup?.[plan] && (
                      <Reveal
                        effect='fadeInRight'
                        duration={0.5}
                        css={css(styles.saveBadge)}
                      >
                        <Badge variant='tag-yellow'>
                          {text_1.textGroup[plan]}
                        </Badge>
                      </Reveal>
                    )}
                    <Icon content={icon} size='sm' mb='2' round />
                    <ContentText content={kicker} variant='h3' />
                    <Flex
                      sx={{ alignItems: `center`, justifyContent: `center` }}
                    >
                      <ContentText
                        content={title}
                        variant='h1'
                        mb='0'
                        mr='2'
                        sx={{
                          transition: `all .4s ease-in`,
                          ...(plan > 0 && styles.prevPrice)
                        }}
                      >
                        {plan > 0
                          ? title?.textGroup?.[0]
                          : title?.textGroup?.[plan]}
                      </ContentText>
                      {plan > 0 && (
                        <Reveal effect='fadeInDeepLeft' duration={0.5}>
                          <ContentText
                            content={title}
                            variant='h1'
                            mb='0'
                            mr='2'
                          >
                            {title?.textGroup?.[plan]}
                          </ContentText>
                        </Reveal>
                      )}
                      <ContentText content={description} mb='0' mt='2' />
                    </Flex>
                    <ContentText content={text_2} mb='0' mt='2' mx='auto' />
                    {collection && (
                      <>
                        <Divider space={2} />
                        {collection.map((props, index) => (
                          <ListItem key={`item-${index}`} {...props} compact />
                        ))}
                      </>
                    )}
                    <ContentButtons content={buttons} space={3} />
                  </ContentContainer>
                </Reveal>
              </Box>
            )
          )}
        </Flex>
      )}
      <ContentButtons content={buttons} />
    </Container>
  )
}

export default WithDefaultContent(PricingBlock01)

import React from 'react'
import { Container, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import QuickSignupForm from '@solid-ui-components/QuickSignupForm'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  title: {
    color: 'alphaDark',
    background: t => `
      linear-gradient(
        125deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.alphaDark} 50.5%)
    `,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`
  },
  subTitle: {
    maxWidth: 1000,
    color:'omegaDarker'
  }
}

const HeroBlock02 = ({
  content: { title, kicker, text_1, buttons, form, images }
}) => (
  <Container sx={{ textAlign: `center` }}>
    <Box>
      <Reveal effect='fadeInDown'>
        <ContentText content={kicker} variant='h4' />
        <ContentText
          content={title}
          variant='h1'
          mb='4'
          mx='auto'
          sx={{
            maxWidth: 650,
            ...(!title?.color && styles.title)
          }}
        />
        
      </Reveal>
     
    </Box>
    <Box sx={{ position: `relative` }}>
      <ContentImages
        content={{ images }}
        loading='eager'
        imagePosition='center'
        imageEffect='fadeInUp'
      />
      <ContentText
          content={text_1}
          variant='h2'
          mt="2"
          mb='4'
          mx='auto'
          sx={styles.subTitle}
        />
    </Box>
  </Container>
)

export default WithDefaultContent(HeroBlock02)
